<template>
  <div class="main">
    <a-button
      class="p-0 mb-3"
      type="link"
      icon="arrow-left"
      size="large"
      @click="$router.go(-1)">
      {{ $t('back') }}
    </a-button>
    <h2>{{ $t('msg.item.title') }}</h2>
    <p class="my-4">{{ $t('msg.item.subtitle') }}</p>
    <div class="card">
      <div class="card-body px-5 py-3">
        <a-list
          size="large"
          :data-source="results">
          <a-list-item
            slot="renderItem"
            slot-scope="item">
            <a-list-item-meta>
              <!-- SKU -->
              <h5 slot="title">
                {{ item.sku }}
              </h5>
              <div slot="description" class="text-secondary">
                <!-- Content -->
                {{ item.content }}
                <!-- Dimensions -->
                <div v-if="item.dimensions">
                  {{ item.dimensions.weight }} kg | {{ item.dimensions.height }} x {{ item.dimensions.width }} x {{ item.dimensions.length }} cm
                </div>
                <!-- Quantity -->
                <div>
                  <span>{{ item.quantity }} un</span>
                </div>
              </div>
              <a-avatar
                slot="avatar"
                :src="item.image_url"
                :size="128"
                shape="square"
                class="border"
                role="button"
                @click="() => onOpen(item)" />
            </a-list-item-meta>
            <div class="d-none d-md-block">
              <span>{{ $t('value') }}:</span>
              <span class="badge badge-light font-size-18 mx-2" v-if="item.value">
                {{ item.value | currency }}
              </span>
              <span class="badge badge-light font-size-18 mx-2" v-else>
                $ -.--
              </span>
            </div>
          </a-list-item>
        </a-list>
      </div>
    </div>
    <a-modal
      :visible="visible"
      :footer="null"
      @cancel="onClose">
      <div class="text-center">
        <img :width="400" :src="selected"  />
      </div>
    </a-modal>
  </div>
</template>
<script>
import lms from '@/api/lms'
export default {
  name: 'ItemList',
  data: function () {
    return {
      results: [],
      visible: false,
      selected: null,
    }
  },
  mounted() {
    this.fetch()
  },
  computed: {
    code() {
      return this.$route.params.shipment
    },
  },
  methods: {
    fetch() {
      return lms.shipment.fetchItems(this.code)
        .then(response => (this.results = response.data))
    },
    onOpen(item) {
      this.selected = item.image_url
      this.visible = true
    },
    onClose() {
      this.visible = false
    },
  },
}
</script>
